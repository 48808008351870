<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions"></div>
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item label="班组">
              <a-select
                class="form-control lg test"
                v-decorator="['leaderId']"
                placeholder="请选择"
                allowClear
                showSearch
                :filterOption="filterByPY"
                :options="leaderList"
              ></a-select>
            </a-form-item>
            <a-form-item label="身份证号">
              <a-input class="form-control" v-decorator="['cardId']" placeholder="请输入身份证号" allowClear></a-input>
            </a-form-item>
            <a-form-item label="工人姓名">
              <a-input class="form-control" v-decorator="['name']" placeholder="请输入工人姓名" allowClear></a-input>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="名称">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入',
                  },
                ],
              },
            ]"
            placeholder="请输入"
          ></a-input>
        </a-form-item>
        <a-form-item label="工种">
          <a-input
            v-decorator="[
              'workerType',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入',
                  },
                ],
              },
            ]"
            placeholder="请输入"
          ></a-input>
        </a-form-item>
        <a-form-item label="工种类型">
          <a-select
            class="form-control"
            v-decorator="[
              'workerKind',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]"
            :options="typesMap.workerKind"
            placeholder="请选择"
          ></a-select>
        </a-form-item>
        <a-form-item label="工种类型分类">
          <a-select
            class="form-control"
            v-decorator="[
              'kindType',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]"
            :options="typesMap.kindType"
            placeholder="请选择"
          ></a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <EditWorkerLeader ref="editWorker"></EditWorkerLeader>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
import EditWorkerLeader from './modules/EditWorkerLeader'
export default {
  components: {
    EditWorkerLeader,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '班组',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: '名称',
        width: '6%',
        align: 'center',
        dataIndex: 'name',
      },
      {
        title: '身份证号码',
        width: '12%',
        align: 'center',
        dataIndex: 'cardId',
      },

      {
        title: '工种',
        width: '6%',
        align: 'center',
        dataIndex: 'workerType',
      },

      {
        title: '工种类型',
        width: '6%',
        align: 'center',
        dataIndex: 'workerKind',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'workerKind')}</span>
        },
      },

      {
        title: '工种类型分类',
        width: '6%',
        align: 'center',
        dataIndex: 'kindType',
        customRender: (text, row, index) => {
          return <span>{this.translateType(text, 'kindType')}</span>
        },
      },
      {
        title: '入场时间',
        width: '10%',
        align: 'center',
        dataIndex: 'beginDate',
      },

      {
        title: '离场时间',
        width: '10%',
        align: 'center',
        dataIndex: 'endDate',
      },
      {
        title: '状态',
        width: '6%',
        align: 'center',
        dataIndex: 'status',
        customRender: (text, row, index) => {
          return <span>{text === 1 ? '在职' : '离职'}</span>
        },
      },
      {
        title: '操作',
        width: '10%',
        align: 'center',
        customRender: (text, row, index) => {
          return (
            <div>
              {row.status === 1 && (
                <span>
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.editLeader(row)
                    }}
                  >
                    班组修改
                  </a>
                </span>
              )}
              {row.status === 1 && (
                <span>
                  <a
                    class="row-action-btn"
                    onClick={() => {
                      this.edit(row)
                    }}
                  >
                    编辑
                  </a>
                </span>
              )}
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {
        workerKind: [
          { value: 0, label: '包工' },
          { value: 1, label: '点工' },
        ],
        kindType: [
          { value: 0, label: '大工' },
          { value: 1, label: '小工' },
        ],
      }
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/project/worker/list', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    edit(row) {
      this.resetModal()
      this.modal.title = '工人信息修改'
      this.modal.visible = true
      this.modal.data = {
        ...row,
      }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { cardId, projectCode } = this.modal.data
            await this.$axios.post('/attendance/web/project/worker/base/info/modify', {
              ...params,
              cardId,
              projectCode,
            })
            this.modal.visible = false
            this.$message.success('操作成功')
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async editLeader(row) {
      const projectLeaderList = await getLeaderList(row.projectCode)
      await this.$refs.editWorker.start({ ...row, projectLeaderList })
      this.$message.success('操作成功')
      this.doQuery()
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
