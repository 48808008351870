<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="modal.form" v-bind="modalFormLayout">
      <a-form-item label="原班组">{{ modal.data.leaderName }}{{ modal.data.leaderCardNumber }}</a-form-item>
      <a-form-item label="最新班组">
        <a-select
          class="form-control lg"
          v-decorator="['leaderId']"
          placeholder="请选择"
          allowClear
          :options="modal.data.projectLeaderList"
          showSearch
          :filterOption="filterByPY"
        ></a-select>
      </a-form-item>
      <a-form-item label="入场时间">
        <a-date-picker class="form-control lg" v-decorator="['entryTime']" placeholder="请选择"></a-date-picker>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'
export default {
  mixins: [toolMixin],
  data() {
    return {
      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '班组变更',
        data: {},
      },
    }
  },
  methods: {
    async start(row) {
      this.resetModal()
      this.modal.visible = true
      this.modal.data = {
        ...row,
      }
      return new Promise((resolve, reject) => {
        this.$once('end', function (state, result) {
          if (state === 'ok') {
            resolve(result)
          }
          if (state === 'cancel') {
            reject(new Error('E_CANCEL'))
          }
          this.modal.visible = false
        })
      })
    },
    async handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = this.formatParams(values, (key, value) => {
            if (key === 'entryTime') {
              return { key, value: value.format('YYYY-MM-DD') }
            }
          })
          try {
            const { projectCode, cardId } = this.modal.data
            await this.$axios.post('/attendance/web/project/worker/leader/modify', {
              ...params,
              projectCode,
              cardId,
            })
            this.$emit('end', 'ok')
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
